import React from "react";
import theme from "theme";
import { Theme, Span, Text, Link, Box, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosBeer, IoIosRestaurant, IoIosFootball, IoMdColorPalette, IoIosMusicalNotes } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | DEUTSCHE SPORTWELT PUB
			</title>
			<meta name={"description"} content={"Where Culture Meets Athletic Passion"} />
			<meta property={"og:title"} content={"Home | DEUTSCHE SPORTWELT PUB"} />
			<meta property={"og:description"} content={"Where Culture Meets Athletic Passion"} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/pub-location-icon-free-vector.jpg?v=2023-10-17T16:23:32.147Z"} />
		</Helmet>
		<Components.Header />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Span
						color="--orange"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						DEUTSCHE{" "}
					</Span>
					Sportwelt Pub
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					Step into the one and only pub where the excitement of sporting glory meets the rich tapestry of German culture. At Deutsche Sportwelt, we celebrate not just the thrill of the game but also the stories, art, and traditions that surround it.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="#"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-orange"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Contact us
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/counter_1.jpg?v=2023-10-17T16:19:15.348Z) center center/cover no-repeat scroll padding-box"
				lg-min-height="400px"
			/>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-8">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
				width="100%"
				min-width="100%"
			/>
			<Box
				display="flex"
				padding="0px 0px 0px 30px"
				margin="0px -10px 0px 0px"
				lg-margin="0px 10px 0px 10px"
				lg-padding="0px 0px 0px 0"
				height="550px"
				min-width="100px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/inside_5.jpg?v=2023-10-18T07:05:43.841Z) 30% 0%/cover repeat scroll padding-box"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 60px 0px 60px"
				lg-margin="0px 0px 50px 0px"
				sm-padding="0px 25px 0px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Unique Features:
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosBeer}
						size="24px"
						color="--orange"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						{" "}BierGarten Library: Sip on our exclusive brews while flipping through rare sports journals, biographies, and historical records. Your beer mat is a bookmark here!
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosRestaurant}
						size="24px"
						color="--orange"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Themed Nights: From medieval jousting feasts to 20th-century Olympic dinners, dine like a sports legend from eras gone by.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosFootball}
						size="24px"
						color="--orange"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Interactive Tables: Play mini table-games related to popular German sports while you wait for your food. Challenge your friends to a mini penalty shoot-out or a mini-putt golf game.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoMdColorPalette}
						size="24px"
						color="--orange"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Artistic Goals Gallery: Admire a collection of art inspired by German sporting heroes and events. Paintings, sculptures, and digital installations - all showcasing the passion of the games.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosMusicalNotes}
						size="24px"
						color="--orange"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Live Sports Ballads: On special nights, local musicians play and sing ballads of legendary sports moments, turning every goal, save, and match into a musical tale.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Schedule-5">
			<Override slot="SectionContent" />
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 500 56px/1.2 --fontFamily-serifGeorgia"
				color="--dark"
				sm-margin="0px 0px 30px 0px"
			>
				Upcoming Events:
			</Text>
			<Text margin="0px 0px 70px 0px" text-align="center" font="--base" color="#6a6e6e">
				Become a member of the Deutsche Sportwelt Club. Enjoy perks like priority bookings, special discounts, and exclusive events.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				align-items="center"
				margin="0px 0px 30px 0px"
				md-flex-direction="column"
				sm-margin="0 0 25px 0"
				border-width="0 0 1px 0"
				border-style="solid"
				border-color="#d1d7de"
				md-padding="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					width="45%"
					padding="0px 35px 0px 0"
					font="normal 500 28px/1.2 --fontFamily-serifGeorgia"
					color="#392b20"
					md-width="100%"
					md-padding="0 0 25px 0"
					md-text-align="center"
				>
					Historic Football Week
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					width="40%"
					padding="0px 25px 0px 0"
					font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					color="--dark"
					md-width="100%"
					md-padding="0 0 15px 0"
					md-text-align="center"
				>
					Relive the golden days with special screenings and themed menus.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				align-items="center"
				margin="0px 0px 30px 0px"
				md-flex-direction="column"
				sm-margin="0 0 25px 0"
				border-width="0 0 1px 0"
				border-style="solid"
				border-color="#d1d7de"
				md-padding="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					width="45%"
					padding="0px 35px 0px 0"
					font="normal 500 28px/1.2 --fontFamily-serifGeorgia"
					color="#392b20"
					md-width="100%"
					md-padding="0 0 25px 0"
					md-text-align="center"
				>
					Wine and Archery Evening
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					width="40%"
					padding="0px 25px 0px 0"
					font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					color="--dark"
					md-width="100%"
					md-padding="0 0 15px 0"
					md-text-align="center"
				>
					Sip on fine wines and try your hand at a bit of friendly target practice. (Safety measures in place!)
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				align-items="center"
				margin="0px 0px 30px 0px"
				md-flex-direction="column"
				sm-margin="0 0 25px 0"
				border-width="0 0 1px 0"
				border-style="solid"
				border-color="#d1d7de"
				md-padding="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					width="45%"
					padding="0px 35px 0px 0"
					font="normal 500 28px/1.2 --fontFamily-serifGeorgia"
					color="#392b20"
					md-width="100%"
					md-padding="0 0 25px 0"
					md-text-align="center"
				>
					Winter Sports Wonderland
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					width="40%"
					padding="0px 25px 0px 0"
					font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					color="--dark"
					md-width="100%"
					md-padding="0 0 15px 0"
					md-text-align="center"
				>
					Experience winter sports, from curling to ice-hockey, in a summer setting.
				</Text>
			</Box>
		</Section>
		<Components.FoodImages />
		<Components.Testimonials />
		<Components.Footer />
	</Theme>;
});